<template>
  <div class="MediaViewContent">
    <PostMediaSlider class="media-visor-slider" v-if="media">
      <div v-for="(media, index) in items" :key="`media-${index}`" :class="` file creator-media-wrap`">
        <img alt="Image" v-if="mediaType(media) === 'image'" :src="media.src" />
        <video v-else-if="mediaType(media) === 'video'" width="100%" controls playsinline :poster="media.thumb" controlslist="nodownload">
          <source :src="`${media.src}`" />
        </video>
      </div>
    </PostMediaSlider>
    <div class="media-visor-slider-options">
      <a class="button" @click="closeVisor"><iconic name="times" /></a>
    </div>
  </div>
</template>

<script>
import MyView from "../../views/View.vue";
import PostMediaSlider from "../widgets/PostMediaSlider.vue";
import Overlay from "../widgets/Overlay.vue";
export default {
  props: ["media", "modal"],
  components: { MyView, PostMediaSlider, Overlay },
  methods: {
    closeVisor: function() {
      this.modal.close(() => {});
    },
  },
  computed: {
    items: function() {
      return this.media;
    },
  },
};
</script>

<style lang="scss">
.view.media-visor {
  .modal-overlay {
    @include Flex(inherit, center, center);
    @include Glass(#000, 0.7);
  }

  .modal-card {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .slick-slider,
  .MediaViewContent,
  .media-visor-slider,
  .modal-card-content {
    width: 100%;
    height: 100%;
  }

  .media-visor-slider {
    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-track {
      background: rgba(0, 0, 0, 0);
    }
    .slick-slide {
      height: 100%;
      max-height: 100%;
      > div,
      .file-media {
        max-height: 100%;
        height: 100%;
      }
      .creator-media-wrap img,
      .creator-media-wrap video {
        width: auto;
        margin: auto;
        max-height: 100vh;
      }
    }
    &-options {
      position: fixed;
      top: $mpadding;
      left: $mpadding;
      z-index: 2;
      .button {
        @include Glass(#000, 0.5);
        @include Flex(inherit, center, center);
        width: $mpadding * 3;
        height: $mpadding * 3;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        &:active,
        &:hover {
          @include Glass(#fff, 0.5);
        }
      }
    }
  }
}
</style>
